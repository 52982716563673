export default [
    {
        headerName: 'Id',
        field: 'id',
    },
    {
        headerName: 'LEA',
        field: 'lea',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'Update Date',
        field: 'updateDate',
    },
    {
        headerName: 'Is Deleted',
        field: 'isDeleted',
        filter: false,
    },
    {
        headerName: 'Student ID',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Tag Name',
        field: 'tagName',
        filter: false,
    },
    {
        headerName: 'Tag Description',
        field: 'tagDescription',
        filter: false,
    },
    {
        headerName: 'Staff ID',
        field: 'staffId',
        filter: false,
    },
    {
        headerName: 'Public',
        field: 'public',
        filter: false,
    },
]
